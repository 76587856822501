import React from "react";

const SkillsImg = (props) => {
  return (
    <svg
      className="svg-box hide-mobile"
      xmlns="http://www.w3.org/2000/svg"
      width="725.497"
      height="445.184"
      data-name="Layer 1"
      viewBox="0 0 925.497 645.184"
    >
      <circle cx="233.088" cy="328.457" r="18.409" fill="#f2f2f2"></circle>
      <circle cx="170.088" cy="540.457" r="11.409" fill="#f2f2f2"></circle>
      <path
        fill="#f2f2f2"
        d="M379.997 21.5H519.9970000000001V23.5H379.997z"
      ></path>
      <path fill="#f2f2f2" d="M417.997 3.5H419.997V22H417.997z"></path>
      <path fill="#f2f2f2" d="M478.997 3.5H480.997V22H478.997z"></path>
      <path fill="#f2f2f2" d="M605.997 505.5H745.997V507.5H605.997z"></path>
      <path fill="#f2f2f2" d="M643.997 487.5H645.997V506H643.997z"></path>
      <path fill="#f2f2f2" d="M704.997 487.5H706.997V506H704.997z"></path>
      <path fill="#f2f2f2" d="M612.997 200.5H752.997V202.5H612.997z"></path>
      <path fill="#f2f2f2" d="M650.997 182.5H652.997V201H650.997z"></path>
      <path fill="#f2f2f2" d="M711.997 182.5H713.997V201H711.997z"></path>
      <path fill="#f2f2f2" d="M612.997 128.5H752.997V130.5H612.997z"></path>
      <path fill="#f2f2f2" d="M650.997 110.5H652.997V129H650.997z"></path>
      <path fill="#f2f2f2" d="M711.997 110.5H713.997V129H711.997z"></path>
      <path fill="#f2f2f2" d="M305.997 138.5H445.997V140.5H305.997z"></path>
      <path fill="#f2f2f2" d="M343.997 120.5H345.997V139H343.997z"></path>
      <path fill="#f2f2f2" d="M404.997 120.5H406.997V139H404.997z"></path>
      <path fill="#f2f2f2" d="M203.997 91.5H343.997V93.5H203.997z"></path>
      <path fill="#f2f2f2" d="M241.997 73.5H243.997V92H241.997z"></path>
      <path fill="#f2f2f2" d="M302.997 73.5H304.997V92H302.997z"></path>
      <path fill="#f2f2f2" d="M140.997 276.5H280.997V278.5H140.997z"></path>
      <path fill="#f2f2f2" d="M178.997 258.5H180.997V277H178.997z"></path>
      <path fill="#f2f2f2" d="M239.997 258.5H241.997V277H239.997z"></path>
      <path fill="#f2f2f2" d="M70.997 480.5H210.997V482.5H70.997z"></path>
      <path fill="#f2f2f2" d="M108.997 462.5H110.997V481H108.997z"></path>
      <path fill="#f2f2f2" d="M169.997 462.5H171.997V481H169.997z"></path>
      <path fill="#f2f2f2" d="M732.997 396.5H872.997V398.5H732.997z"></path>
      <path fill="#f2f2f2" d="M770.997 378.5H772.997V397H770.997z"></path>
      <path fill="#f2f2f2" d="M831.997 378.5H833.997V397H831.997z"></path>
      <path fill="#f2f2f2" d="M691.997 268.5H831.997V270.5H691.997z"></path>
      <path fill="#f2f2f2" d="M791.997 270H793.997V288.5H791.997z"></path>
      <path fill="#f2f2f2" d="M730.997 270H732.997V288.5H730.997z"></path>
      <path fill="#f2f2f2" d="M13.997 118.5H153.997V120.5H13.997z"></path>
      <path fill="#f2f2f2" d="M113.997 120H115.997V138.5H113.997z"></path>
      <path fill="#f2f2f2" d="M52.997 120H54.997V138.5H52.997z"></path>
      <path fill="#f2f2f2" d="M22.997 380.5H162.997V382.5H22.997z"></path>
      <path fill="#f2f2f2" d="M122.997 382H124.997V400.5H122.997z"></path>
      <path fill="#f2f2f2" d="M61.997 382H63.997V400.5H61.997z"></path>
      <path
        fill="#f2f2f2"
        d="M463.997 53.5H603.9970000000001V55.5H463.997z"
      ></path>
      <path fill="#f2f2f2" d="M563.997 55H565.997V73.5H563.997z"></path>
      <path fill="#f2f2f2" d="M502.997 55H504.997V73.5H502.997z"></path>
      <path fill="#f2f2f2" d="M70.997 192.5H210.997V194.5H70.997z"></path>
      <path fill="#f2f2f2" d="M108.997 174.5H110.997V193H108.997z"></path>
      <path fill="#f2f2f2" d="M169.997 174.5H171.997V193H169.997z"></path>
      <path
        fill={props.boardColor}
        d="M370.748 300.408v471h436v-471zm218 34a15 15 0 1115-15 15 15 0 01-15 15zM174.506 731.205c6.914 25.558 30.596 41.387 30.596 41.387s12.473-25.609 5.559-51.167-30.596-41.387-30.596-41.387-12.473 25.609-5.559 51.167z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path
        fill="#00b0ff"
        d="M184.666 725.712c18.972 18.469 21.29 46.86 21.29 46.86s-28.443-1.554-47.415-20.022-21.29-46.86-21.29-46.86 28.443 1.554 47.415 20.022z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path fill={props.boardColor} d="M16.497 643H925.497V645H16.497z"></path>
      <path
        fill={props.boardColor}
        d="M329.248 708.746a22.031 22.031 0 00-14.03-20.5 22 22 0 10-15.938 0 21.991 21.991 0 000 41 22 22 0 1015.937 0 22.031 22.031 0 0014.031-20.5zm-42-41a20 20 0 1124.513 19.467 22.023 22.023 0 00-9.025 0 20.02 20.02 0 01-15.488-19.467zm40 82a20 20 0 11-24.512-19.467 22.024 22.024 0 009.025 0 20.02 20.02 0 0115.487 19.467zm-15.487-21.533a22.023 22.023 0 00-9.025 0 19.979 19.979 0 010-38.934 22.024 22.024 0 009.025 0 19.979 19.979 0 010 38.934z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path
        fill="#f2f2f2"
        d="M648.249 398.908h-229a7 7 0 110-14h229a7 7 0 010 14zM648.249 470.908h-229a7 7 0 110-14h229a7 7 0 010 14zM648.249 542.908h-229a7 7 0 110-14h229a7 7 0 010 14zM648.249 614.908h-229a7 7 0 110-14h229a7 7 0 010 14zM648.249 686.908h-229a7 7 0 110-14h229a7 7 0 010 14z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path
        fill="#00b0ff"
        d="M608.249 398.908h-190a7 7 0 110-14h190a7 7 0 010 14zM549.249 470.908h-131a7 7 0 110-14h131a7 7 0 010 14zM477.248 542.908h-59a7 7 0 110-14h59a7 7 0 010 14zM626.249 614.908h-208a7 7 0 110-14h208a7 7 0 010 14zM580.249 686.908h-162a7 7 0 110-14h162a7 7 0 010 14z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path fill="#00b0ff" d="M603.997 257.5H605.997V271.5H603.997z"></path>
      <path
        fill="#00b0ff"
        d="M758.249 399.908a8 8 0 118-8 8.01 8.01 0 01-8 8zm0-14a6 6 0 106 6 6.007 6.007 0 00-6-6z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path fill="#00b0ff" d="M603.997 329.5H605.997V343.5H603.997z"></path>
      <path
        fill="#00b0ff"
        d="M758.249 471.908a8 8 0 118-8 8.01 8.01 0 01-8 8zm0-14a6 6 0 106 6 6.007 6.007 0 00-6-6z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path fill="#00b0ff" d="M603.997 401.5H605.997V415.5H603.997z"></path>
      <path
        fill="#00b0ff"
        d="M758.249 543.908a8 8 0 118-8 8.01 8.01 0 01-8 8zm0-14a6 6 0 106 6 6.007 6.007 0 00-6-6z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path fill="#00b0ff" d="M603.997 473.5H605.997V487.5H603.997z"></path>
      <path
        fill="#00b0ff"
        d="M758.249 615.908a8 8 0 118-8 8.01 8.01 0 01-8 8zm0-14a6 6 0 106 6 6.007 6.007 0 00-6-6z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path fill="#00b0ff" d="M603.997 545.5H605.997V559.5H603.997z"></path>
      <path
        fill="#00b0ff"
        d="M758.249 687.908a8 8 0 118-8 8.01 8.01 0 01-8 8zm0-14a6 6 0 106 6 6.007 6.007 0 00-6-6z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <circle cx="620.997" cy="264.5" r="4" fill="#00b0ff"></circle>
      <circle cx="620.997" cy="480.5" r="4" fill="#00b0ff"></circle>
      <circle cx="620.997" cy="552.5" r="4" fill="#00b0ff"></circle>
      <circle cx="451.497" cy="192" r="7" fill={props.boardColor}></circle>
      <circle cx="451.497" cy="7" r="7" fill={props.boardColor}></circle>
      <path fill={props.boardColor} d="M450.497 7H452.497V192H450.497z"></path>
      <path
        fill="#2f2e41"
        d="M993.865 511.887v24.775s5.964 39.915 5.964 74.783-9.634 116.075-9.634 116.075-19.27 6.882-20.646-3.67-5.047-66.525-5.047-74.784-16.058-71.113-16.058-71.113l-10.093 69.737s3.212 82.583 0 84.877-18.81 6.423-20.646 2.294-11.47-92.677-11.47-92.677-7.8-115.616-3.211-122.498 8.717-12.387 8.717-12.387 65.607-16.058 82.124 4.588z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path
        fill="#2f2e41"
        d="M987.9 724.767l1.151 7.479a73.664 73.664 0 01.752 7.699c.18 3.757.804 9.545 2.946 10.463 3.211 1.376 24.056 18.956 9.374 19.873s-22.026 2.503-26.374-.873c-5.142-3.992.682-2.185-1.612-2.185s-5.047 0-5.964-3.67 1.376-10.093 1.376-10.093v-28.693zM919.397 724.408l-1.15 7.479a73.664 73.664 0 00-.753 7.699c-.179 3.756-.804 9.545-2.945 10.462-3.212 1.377-24.056 18.956-9.375 19.874s22.026 2.503 26.375-.874c5.141-3.991-.682-2.184 1.612-2.184s5.046 0 5.964-3.67-1.377-10.094-1.377-10.094v-28.692z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path
        fill={props.skinColor}
        d="M932.387 312.77s7.8 19.27 13.305 22.481 2.752 17.893 2.752 17.893l-13.763 19.27H923.67l-13.305-22.94s-.918-15.14-3.67-18.81 25.692-17.894 25.692-17.894z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path
        d="M932.387 312.77s7.8 19.27 13.305 22.481 2.752 17.893 2.752 17.893l-13.763 19.27H923.67l-13.305-22.94s-.918-15.14-3.67-18.81 25.692-17.894 25.692-17.894z"
        opacity="0.1"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path
        fill="#00b0ff"
        d="M943.857 352.226l20.645 4.588 15.14 152.32s-8.258 1.376-16.516 12.387-18.352 9.176-23.399 6.424-25.233-10.553-25.692-13.764.917-59.643.917-59.643l-12.846-88.089 6.423-9.635H920l9.176 7.8z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <circle
        cx="775.407"
        cy="185.362"
        r="23.857"
        fill={props.skinColor}
      ></circle>
      <path
        fill="#575a89"
        d="M947.068 344.427s44.962 4.588 49.091 14.223-2.753 52.302-2.753 52.302 16.517 37.162 16.058 45.88 15.6 68.36-1.835 61.478-11.929-24.775-20.646-11.929-12.387 11.011-12.387 11.011-8.717 14.223-11.47 12.846-4.588-11.928 1.376-16.975 5.047-13.764-6.423-30.28-23.398-103.229-16.516-116.534 5.505-22.022 5.505-22.022z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path
        fill={props.skinColor}
        d="M856.614 393.196L848.814 400.537 839.179 394.114 843.309 384.479 856.614 393.196z"
      ></path>
      <path
        fill="#575a89"
        d="M914.035 352.226s-34.868 12.847-34.868 26.61 21.104 73.866 21.104 73.866 0 15.6-.918 19.27-15.352 35.682-2.752 38.538c7.865 1.783 14.327 6.466 14.91 13.076.694 7.849-5.315 17.755-4.817 19.499.918 3.211 6.423 7.8 5.965 5.505s3.67-35.327 10.093-50.008-2.753-113.322-4.588-126.169a141.988 141.988 0 00-4.13-20.187z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path
        fill={props.skinColor}
        d="M758.432 392.278L766.69 412.007 772.884 399.39 765.772 383.561 758.432 392.278z"
      ></path>
      <path
        fill="#00b0ff"
        d="M939.536 327.55s7.532 3.113 12.12 3.113 23.398 9.635 16.975 15.6-16.057 7.34-20.645 16.057-6.882 10.093-6.882 10.093l-8.168-10.972s11.38-12.885 10.92-17.014-4.32-16.876-4.32-16.876zM909.125 338.582s-5.642 2.175-7.019 5.845-10.093 10.552-6.423 12.387 19.728 9.176 22.94 10.553 9.197-3.905 9.197-3.905-8.05-4.583-8.05-7.795-10.644-17.085-10.644-17.085z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path
        fill="#575a89"
        d="M890.178 372.413l-11.011 2.753s-15.14 67.902-10.553 84.877 26.152 67.902 26.152 67.902l13.763-16.058s-6.003-36.515-9.425-38.904-1.127-7.434-1.127-8.81 1.377-.46-1.835-3.671 11.929-21.105 7.8-26.151-13.764-61.938-13.764-61.938z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path
        d="M998.682 385.03l-6.652-25.004s17.893 12.846 21.563 31.657 17.893 56.431 8.717 72.948-25.233 64.231-30.739 63.314-18.81-12.388-19.27-14.223 23.858-44.044 20.188-47.256-2.753-11.47 0-13.305 5.964-10.552 3.67-10.093-9.176-4.13-7.8-9.176-6.881-38.998-6.881-38.998z"
        opacity="0.1"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path
        fill="#575a89"
        d="M989.736 356.814l6.423 1.836s17.893 12.846 21.563 31.656 17.893 56.432 8.717 72.949-25.233 64.23-30.739 63.313-18.81-12.387-19.27-14.222 23.858-44.045 20.188-47.256-2.753-11.47 0-13.305 5.964-10.552 3.67-10.094-9.176-4.129-7.8-9.176-6.881-38.997-6.881-38.997z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <path
        fill={props.hairColor}
        d="M931.172 291.235l4.475-1.627s-9.356-9.356-22.373-8.542l3.661-3.661s-8.95-3.255-17.085 5.288c-4.277 4.49-9.225 9.769-12.31 15.715h-4.791l2 4-7 4 7.184-.719a20.184 20.184 0 00.68 10.36l1.627 4.474s6.509-13.017 6.509-14.644v4.068s4.474-3.66 4.474-6.102l2.44 2.848 1.221-4.475 15.051 4.475-2.44-3.661 9.355 1.22-3.66-4.474s10.576 5.288 10.982 9.762 5.695 10.17 5.695 10.17l2.44-4.068s3.662-18.305-8.135-24.407z"
        transform="translate(-137.251 -127.408)"
      ></path>
      <ellipse
        cx="792.997"
        cy="182.5"
        fill={props.skinColor}
        rx="3.5"
        ry="5.5"
      ></ellipse>
    </svg>
  );
};

export default SkillsImg;
